@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "../../../../assets/scss/custom/functions";
@import "../../../../assets/scss/custom/variables";

.event_main_container {
//   background-color: #F5FAFE;
  // padding-bottom: 200px;
  padding-bottom: $pd50;
  margin-left: 6rem;
  margin-right: 6rem;
}

// left container
.event_title {
    font-size: $fs40;
    font-weight: bold;
    font-family: $font_2;
}
.event_search_title {
    font-size: $fs40;
    font-weight: bold;
    color: $fca-dark;
    font-family: $font_1;
}

// right container
.sticky_right {
    position: -webkit-sticky;
    position: sticky;
    top: 0; /* Adjust this value as needed to position the column */
    z-index: 1000; 
    transition: all .3s ease;
    padding: 20px 0px 0 0;
}
// search container
.search_container {
    padding: 1rem;
    margin-bottom: 1rem;
}
.search_title {
    font-size: $fs18;
    font-weight: bold;
    font-family: $font_1;
}
.search_input {
    display: flex;
    flex: 1;
    background-color: #F7F7F7;
    border-color: #EEEEEE;
    color: #AAAAAA;
    font-family: $font_1;
    font-size: 15px;
    padding: 0.725rem 0.85rem;
    margin-left: 10px;
}
.search_input_icon {
    background-color: #F7F7F7;
    border-color: #EEEEEE;
    color: #AAAAAA;
    font-size: 15px;
    width: 30px;
    height: 30px;
}
.input_container {
    //height: 60px;
    background-color: #F7F7F7;
}
// this week container
.thisweek_container {
    padding: 1rem;
  
}
.thisweek_event_image {
    height: 60px;
    width: 60px;
    overflow: hidden;
}
.thisweek_event_name_text {
    font-size: 14px;
    color: $black;
    font-weight: 600;
    font-family: $font_1;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
            line-clamp: 2; 
    -webkit-box-orient: vertical;
}
.thisweek_event_date_text {
    font-weight: 600;
    font-size: 12px;
    color: $fca-green;
    font-family: $font_1;
}

// featured event card
.featured_event_card_container {
    display: flex;
    flex-direction: row;
    overflow-wrap: anywhere;
    height: 100%;
}
.featured_event_card_data_container {
    flex: 1;
    flex-direction: column;
    padding: $fs30;
}
.featured_event_card_banner_container {
}
.feature_event_banner_image {
    width: 380px;
    height: 100% !important;
    object-fit: cover;
    transition: all 0.5s ease-in-out; /* Add a height transition */
}
.featured_event_date_container {
    background-color: $fca-green;
    padding: 5px 20px;
    border-radius: 4px;
    box-shadow: 2.0px 4.0px 4.0px hsl(0deg 0% 0% / 0.20);


    display: flex;
    flex-direction: row;
    align-items: center;
}
.featured_event_date_icon {
    color: #fff;
}
.featured_event_date_text {
    font-size: 12px;
    color: #fff;
    font-weight: bold;
    font-family: $font_1;
}
.featured_event_category_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.featured_event_category_text {
    font-size: $fs14;
    font-weight: bold;
    font-family: $font_1;
    margin-right: 0.5rem;
}
.featured_event_title_text {
    font-size: $fs22;
    font-weight: bold;
    font-family: $font_1;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
            line-clamp: 2; 
    -webkit-box-orient: vertical;
}
.featured_event_author_text {
    font-size: $fs14;
    font-family: $font_1;
}
.featured_event_author_image {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    overflow: hidden;
}
.featured_event_author_initial_div {
    display: flex;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    background-color: $fca-blue;
}
.featured_event_author_initial_text {
    font-size: 14px;
    font-weight: 700;
    font-family: $font_1;
    color: $white;
}
.featured_event_author_name {
    font-size: $fs14;
    color: $black;
    font-weight: 700;
    font-family: $font_1;
}
.featured_event_desc_text {
    font-size: $fs14;
    font-family: $font_1;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
            line-clamp: 3; 
    -webkit-box-orient: vertical;
}
.featured_event_viewdetails_button {
    background-color: transparent;
    border: none;
    box-shadow: none;
}
.featured_event_viewdetails_button_text {
    font-size: $fs14;
    font-weight: 600;
    font-family: $font_1;
    text-decoration-line: underline;
}

// available event
.avaiable_event_date_container {
    // height: 100%;
    background-color: #ECF9F6;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.available_event_date_day_text {
    font-size: $fs30;
    font-weight: bold;
    font-family: $font_1;
    color: $fca-green;
    text-align: center;
}
.available_event_date_month_text {
    font-size: $fs15;
    font-weight: bold;
    font-family: $font_1;
    color: $fca-green;
    text-align: center;
}
.available_event_date_time_text {
    font-size: $fs15;
    font-weight: bold;
    font-family: $font_1;
    color: $fca-green;
    text-align: center;
}
.avaiable_event_details_container {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.available_event_category_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.available_event_category_text {
    font-size: $fs13;
    font-weight: bold;
    font-family: $font_1;
    margin-right: 0.5rem;
}
.available_event_title_text {
    font-size: $fs18;
    font-weight: bold;
    font-family: $font_1;
    color: $fca-dark;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
            line-clamp: 2; 
    -webkit-box-orient: vertical;
}
.available_event_desc_text {
    font-size: $fs14;
    font-family: $font_1;
    color: $fca-dark;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
            line-clamp: 2; 
    -webkit-box-orient: vertical;
}
.available_event_viewdetails_button {
    background-color: transparent;
    border: none;
    box-shadow: none;
}
.available_event_viewdetails_button_text {
    font-size: $fs14;
    font-weight: 600;
    font-family: $font_1;
    text-decoration-line: underline;
}
.available_event_banner_image {
    width: 200px;
    height: 100% !important;
    object-fit: cover;
    transition: all 0.5s ease-in-out; /* Add a height transition */
}
.available_event_card {
    overflow: hidden;
    transition: transform 0.3s ease-in-out, box-shadow 0.5s ease-in-out; /* Add a transition effect to the transform property */
}
.available_event_card:hover {
    box-shadow: 0px calc(3px + (5 - 3) * (100vw - 375px) / (1900 - 375)) calc(5px + (10 - 5) * (100vw - 375px) / (1900 - 375)) rgba(16, 24, 32, 0.298);
    transform: translateY(-5px); /* Shift the card up when hovered */
}
.available_event_card:hover .available_event_banner_image {
    transform: scale(1.05); /* Zoom in the image when the container is hovered */
}
.available_event_card_container {
    display: flex;
    flex-direction: row;
}
.available_event_card_data_container {
    flex: 1;
    display: flex;
    flex-direction: row;
}
.available_event_card_banner_container {
}

// EVENT DETAILS
// event details side register container
.event_side_register_container {
    padding: 1rem;
    margin-bottom: 1rem;
}
.event_side_register_title {
    font-size: $fs18;
    font-weight: bold;
    font-family: $font_1;
}
.event_side_register_details_icon {
    color: $fca-blue;
    font-size: 16px;
}
.event_side_register_details_text {
    font-size: $fs14;
    color: $fca-dark;
    font-family: $font_1;
}
.event_side_register_fee_container {
    padding: 0.5rem;
    background-color: #FFBD4315;
    margin-bottom: 1rem;
}
.event_side_register_fee_title {
    font-size: $fs12;
    color: $fca-dark;
    font-family: $font_1;
}
.event_side_register_button_title {
    font-size: $fs14;
    color: #fff;
    font-family: $font_1;
    font-weight: 500;
}
.event_side_register_addtocalendar_button {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    text-decoration: underline;
    color: $fca-dark; /* Set the color of the text and underline */
    outline: none; /* Remove the default button focus outline */
}
.event_side_register_addtocalendar_button:hover {
    text-decoration: none; /* Remove underline on hover */
}
.event_side_register_addtocalendar_title {
    font-size: $fs14;
    color: $fca-dark;
    font-family: $font_1;
    font-weight: 600;
}
.event_side_register_completed_text {
    font-size: $fs14;
    color: $fca-dark;
    font-family: $font_1;
    font-weight: bold;
    align-self: center;
}
//event details left container
.event_details_category_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.event_details_category_text {
    font-size: $fs14;
    font-weight: bold;
    font-family: $font_1;
    margin-right: 0.5rem;
}
.event_details_title_text {
    font-size: $fs40;
    font-family: $font_2;
    color: $fca-dark;
}
.event_details_description_text {
    font-size: $fs14;
    font-family: $font_1;
    color: $fca-dark;
}
.event_details_author_text {
    font-size: $fs14;
    font-family: $font_1;
}
.event_details_author_image {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    overflow: hidden;
}
.event_details_author_initial_div {
    display: flex;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    background-color: $fca-blue;
}
.event_details_author_initial_text {
    font-size: 14px;
    font-weight: 700;
    font-family: $font_1;
    color: $white;
}
.event_details_author_name {
    font-size: $fs14;
    color: $black;
    font-weight: 700;
    font-family: $font_1;
}
.event_details_share_button {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    color: $fca-dark; /* Set the color of the text and underline */
    outline: none; /* Remove the default button focus outline */
}
.event_details_share_button:hover {
    text-decoration: none; /* Remove underline on hover */
}
.event_details_share_button_icon {
    color: $fca-blue;
    font-size: 16px;
}
.event_details_share_button_title {
    font-size: $fs14;
    color: $fca-dark;
    font-family: $font_1;
    font-weight: 600;
}
.event_details_top_share_button {
    background-color: #F7F7F7;
    border: 1px solid #EEEEEE;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.14);
}
.event_details_top_share_button_icon {
    color: $fca-blue;
    font-size: 18px;
}
.event_details_top_back_button {
    background-color: #F7F7F7;
    border: 1px solid #EEEEEE;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.14);

}
.event_details_top_back_button_icon {
    color: $fca-dark;
    font-size: 18px;
}
.event_details_image {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit:cover;
    object-position: center;
}
.event_details_content{
    margin-bottom: 1.5rem;
    overflow: hidden;
}
.event_details_mobile_main_container {
    position: fixed;
    background-color: rgb(0,0,0,0.5);
    z-index: 999;
    bottom: 0;
    left: 0;
    width: 100%;
    
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}
.event_details_mobile_main_container_active {
    height: 100%;
    align-items: flex-end;
    display: flex;
}
.event_details_mobile_container {
    background-color: #fff;
    z-index: 1000;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.1);
    padding: 0.5rem 2rem 1rem 2rem;

    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}
.event_details_mobile_button_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.event_details_mobile_details_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.event_details_mobile_title_container {
    margin: 0 auto
}
// others event
.other_event_card_container {
    background-color: #FAFAFA;
    // box-shadow: none;
    transition: transform 0.3s ease-in-out, box-shadow 0.5s ease-in-out; /* Add a transition effect to the transform property */
}
.other_event_card_container:hover {
    box-shadow: 0px calc(3px + (5 - 3) * (100vw - 375px) / (1900 - 375)) calc(5px + (10 - 5) * (100vw - 375px) / (1900 - 375)) rgba(16, 24, 32, 0.098);
    transform: translateY(-5px); /* Shift the card up when hovered */
}
.other_event_card_container:hover .other_event_banner_image {
    transform: scale(1.05); /* Zoom in the image when the container is hovered */
}
.other_event_banner_image {
    width: 100% !important;
    height: 300px !important;
    object-fit: cover;
    transition: all 0.5s ease-in-out; /* Add a height transition */
}
.other_event_main_date_container {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.5rem;
}
.other_event_date_container {
    background-color: $fca-green;
    padding: 5px 10px;
    border-radius: 4px;
    box-shadow: 2.0px 4.0px 4.0px hsl(0deg 0% 0% / 0.20);
    border: 1px solid #fff;

    display: flex;
    flex-direction: row;
    align-items: center;
}
.other_event_date_icon {
    color: #fff;
    font-size: 16px;
}
.other_event_date_text {
    font-size: 12px;
    color: #fff;
    font-weight: bold;
    font-family: $font_1;
}
.other_event_card_data_container {
    padding: $fs30;
}
.other_event_category_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.other_event_category_text {
    font-size: $fs14;
    font-weight: bold;
    font-family: $font_1;
    margin-right: 0.5rem;
}
.other_event_title_text {
    font-size: $fs18;
    color: $fca-dark;
    font-weight: bold;
    font-family: $font_1;
}
.other_event_desc_text {
    font-size: $fs15;
    color: $fca-dark;
    font-family: $font_1;
}
// event register modal
.register_modal_close_container {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    align-items: center;
    align-self: flex-end;
}
.register_modal_close_icon {
    font-size: 1.5rem;
    color: $fca-dark
}
.register_modal_header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1.5rem;
    padding-top: 0;
    border-bottom: 0 solid #e9ecef;
}
.register_modal_header_title {
    font-size: $fs26;
    color: $fca-dark;
    font-family: $font_2;
}
.register_modal_event_title {
    font-size: $fs26;
    color: $fca-green;
    font-family: $font_2;
    font-weight: 600;
}
.register_modal_header_desc {
    font-size: $fs14;
    color: $fca-dark;
    font-family: $font_1;
}
.register_modal_ticket_title {
    font-size: $fs14;
    color: $fca-dark;
    font-weight: 600;
    font-family: $font_1;
}
.register_modal_regfee_container {
    display: flex;
    flex-direction: column;
}
.register_modal_regfee_title_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.register_modal_regfee_title {
    font-size: $fs14;
    color: $fca-dark;
    font-family: $font_1;
}
.register_modal_tnc_title {
    font-size: $fs16;
    color: $fca-dark;
    font-family: $font_1;
}
.register_modal_regfee_price {
    font-size: $fs20;
    color: $fca-dark;
    font-weight: 600;
    font-family: $font_1;
}
.register_modal_regfee_desc_container {
    background-color: #FFBD4315;
    padding: 0.5rem 1rem;
}
.register_modal_regfee_desc {
    font-size: $fs12;
    color: $fca-dark;
    font-family: $font_1;
}
.register_modal_button_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.register_modal_button_cancel_title {
    font-size: $fs14;
    color: $fca-dark;
    font-family: $font_1;
    font-weight: bold;
}
.register_modal_button_proceed_title {
    font-size: $fs14;
    color: #fff;
    font-family: $font_1;
    font-weight: 600;
}
.register_modal_ticket_select {
    background-color: #fff;
}

@media (max-width: 1499px) {
    .feature_event_banner_image {
        width: 350px;
        height: 100% !important;
        object-fit: cover;
    }
}
@media (max-width: 1399px) {
    .event_main_container {
        margin-left: 5rem;
        margin-right: 5rem;
    }
    .feature_event_banner_image {
        width: 320px;
        height: 100% !important;
        object-fit: cover;
    }
}

@media (max-width: 1199px) {
    .event_main_container {
        margin-left: 2rem;
        margin-right: 2rem;
    }
    .feature_event_banner_image {
        width: 300px;
        height: 100% !important;
        object-fit: cover;
    }
}

@media (max-width: 991px) {
    .event_main_container {
        margin-left: 3rem;
        margin-right: 3rem;
    }
    .featured_event_card_container {
        display: flex;
        flex-direction: column;
    }
    .featured_event_card_data_container {
        flex: 1;
        flex-direction: column;
        padding: $fs30;
    }
    .featured_event_card_banner_container {
        flex: 1;
        order: -1;
    }
    .feature_event_banner_image {
        width: 100% !important;
        height: 300px !important;
        object-fit: cover;
    }
    .featured_event_card_data_container {
        padding-top: 0;
    }
    .feature_event_main_date_container {
        position: absolute;
        top: 0;
    }
    .featured_event_date_container {
       margin-top: 14px;
       margin-left: 14px;
       border: 1px solid #fff;
       padding: 5px 8px;
    }
    .available_event_card_container {
        display: flex;
        flex-direction: column;
    }
    .available_event_card_data_container {
        flex: 1;
    }
    .available_event_card_banner_container {
        flex: 1;
        order: -1;
    }
    .available_event_banner_image {
        width: 100% !important;
        height: 300px !important;
    }
}

@media (max-width: 767px) {
    .event_main_container {
        margin-left: 2rem;
        margin-right: 2rem;
    }
    .feature_event_banner_image {
        width: 100% !important;
        height: 280px !important;
        object-fit: cover;
    }
    .other_event_banner_image {
        width: 100% !important;
        height: 280px !important;
        object-fit: cover;
    }
    .available_event_banner_image {
        width: 100% !important;
        height: 280px !important;
    }
}

@media (max-width: 575px) {
    .event_main_container {
        margin-left: 0rem;
        margin-right: 0rem;
    }
    .feature_event_banner_image {
        width: 100% !important;
        height: 240px !important;
        object-fit: cover;
    }
    .other_event_banner_image {
        width: 100% !important;
        height: 240px !important;
        object-fit: cover;
    }
    .available_event_banner_image {
        width: 100% !important;
        height: 240px !important;
    }
    .sticky_right {
        padding: 0px 0px 0 0;
    }
}